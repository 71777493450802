export type Lang = 'it' | 'en'
export type Pagina = {
	id: string
} & Record<Lang, {
	nome: string
	url: string
}>



export type Punto = {
	id: string
	x: number
	y: number
	pagine: string[]
	dbgbg?: string
}


export const ingleseAttivo = false


export const luoghi: Record<string, Pagina> = {}
export const pagine: Record<string, Pagina> = {}
export const menu: Record<string, Pagina> = {}
export const punti: Punto[] = []

export const testi: string[] = ['footer']


function pu(x: number, y: number, dbgbg: string | undefined, ...pagine: string[]) {
	punti.push({ x, y, pagine, id: pagine.join('_')/*, dbgbg*/ })
}

function l(nomeCorto: string, anno: number) {
	const nome = nomeCorto + " – " + anno
	const id = nomeCorto.toLowerCase().replace(/ /g, '') + anno
	const x = {
		id,
		it: {
			nome,
			url: '/it/p/' + id
		},
		en: {
			nome: nome + " (en)",
			url: '/en/p/' + id
		},
	}

	luoghi[x.id] = x
	pagine[x.id] = x
}


function pa(nomeCorto: string) {
	const nome = nomeCorto
	const id = nomeCorto.toLowerCase().replace(/ /g, '_')
	const x = {
		id,
		it: {
			nome,
			url: '/it/p/' + id
		},
		en: {
			nome: nome + " (en)",
			url: '/en/p/' + id
		},
	}

	pagine[x.id] = x
	menu[x.id] = x
}




l("Castellamonte", 1774)
l("Parma", 1810)
l("Corsica", 1831)
l("Torino", 1833)
l("Tangeri", 1834)
l("Boston", 1836)
l("Londra", 1839)
l("Canada", 1842)
l("Londra", 1843)
l("Parma", 1848)
l("Londra", 1849)
l("Sicilia", 1860)
l("Istanbul", 1875)
l("Russia", 1882)
l("Firenze", 1888)
l("Galles", 1895)



pa("Amici di Gallenga")
pa("Aneddoti")
pa("Bio")
pa("Chi siamo")
pa("Luoghi")
pa("I Blackgown Papers")


pu(780, 1120, 'red', 'parma1810', 'parma1848')
pu(822, 1193, 'pink', 'firenze1888')
pu(546, 775, 'purple', 'londra1839', 'londra1843', 'londra1849')
pu(605, 1105, 'orange', 'castellamonte1774')
pu(880, 1468, 'yellow', 'sicilia1860')
pu(1435, 1321, 'blue', 'istanbul1875')
pu(698, 1240, 'brown', 'corsica1831')
pu(662, 1097, 'azure', 'torino1833')
pu(140, 1395, 'blue', 'tangeri1834')
pu(437, 755, 'teal', 'galles1895')
pu(1540, 614, 'fuchsia', 'russia1882')
pu(81, 500, 'deeppink', 'boston1836')
pu(81, 361, 'firebrick', 'canada1842')


