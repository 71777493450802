import * as React from "react"
import { useState } from "react"
import * as styles from './nav.module.scss'
import { Link } from 'gatsby'
import { ingleseAttivo, Lang, pagine } from "./pagine"
import { ImagoPicture } from "./ImagoPic"



export default function Nav(props: { lang: Lang }) {

	const [mmenu, setMmenu] = useState(false)

	function mm(what?: boolean) {
		if (what === undefined)
			setMmenu(!mmenu)
		else
			setMmenu(what)
	}


	return <>
		<nav className={styles.nav + " " + (mmenu ? '' : styles.mobnascosto)}>
			<ul className={styles.menu}>
				{
					props.lang == 'en' ?
						<li key="home"><Link to="/en">Map</Link></li>
						:
						<li key="home"><Link to="/">Mappa</Link></li>
				}
				{
					[
						pagine['bio'],
						pagine['aneddoti'],
						pagine['luoghi'],
						pagine['chi_siamo'],
						pagine['amici_di_gallenga'],
						pagine['i_blackgown_papers']
					].map(l => {
						const la = l[props.lang]
						return <li key={l.id} ><Link to={la.url}>{la.nome}</Link></li>
					})
				}
				{
					ingleseAttivo && (
						props.lang == 'en' ?
							<li key="it"><Link to="/"><ImagoPicture className={styles.bandierina} name="sito/it.png" alt="Italian" /> Italian</Link></li>
							:
							<li key="en"><Link to="/en"><ImagoPicture className={styles.bandierina} name="sito/en.png" alt="English" /> English</Link></li>
					)

				}
			</ul>
		</nav>
		<button className={styles.ham} onClick={() => mm()}>☰</button>

	</>

}


// export default function Nav() {


// 	const [sub, setSub] = useState<[boolean, Date | undefined]>([false, undefined])
// 	const [mmenu, setMmenu] = useState(false)


// 	function ss(what?: boolean) {
// 		if (sub[1] && (new Date().getTime() - sub[1].getTime() < 100)) {
// 			// console.log('ignoro')
// 			return
// 		}
// 		if (what === undefined) {
// 			setSub([!sub[0], new Date()])
// 		}
// 		else
// 			setSub([what, new Date()])
// 	}

// 	function mm(what?: boolean) {
// 		if (what === undefined)
// 			setMmenu(!mmenu)
// 		else
// 			setMmenu(what)
// 	}



// 	return <>
// 		<nav className={styles.nav + " " + (mmenu ? '' : styles.mobnascosto)}>
// 			<ul className={styles.menu}>
// 				<li><Link to="/">Mappa</Link></li>
// 				<li><Link to="/bio">Bio</Link></li>
// 				<li><Link to="/aneddoti">Aneddoti</Link></li>
// 				<li onMouseEnter={() => ss(true)} onMouseLeave={() => ss(false)}>
// 					<button onClick={() => ss(undefined)}>Luoghi</button>
// 					<ul className={styles.sub} style={{ display: !sub[0] ? 'none' : '' }}>
// 						<li><Link onClick={() => ss(false)} to="/p/parma">Parma</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/firenze">Firenze</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/cisa">Passo della Cisa</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/londra">Londra</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/galles">Galles</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/castellamonte">Castellamonte</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/marsiglia">Marsiglia</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/spagna">Spagna</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/corsica">Corsica</Link></li>
// 						<li><Link onClick={() => ss(false)} to="/p/genova">Genova</Link></li>
// 					</ul>
// 				</li>
// 				<li><a href="/chisiamo">Chi siamo</a></li>
// 				<li><a href="/amici">Amici di Gallenga</a></li>
// 			</ul>
// 		</nav>
// 		<button className={styles.ham} onClick={() => mm()}>☰</button>

// 	</>

// }