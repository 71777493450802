import * as React from "react"
import { imagoSrc } from "./imago"


export function ImagoPicture(props: {
	name: string,
	className?: string
	style?: React.CSSProperties
	alt?: string
}) {

	const attrs = imagoSrc(props.name)

	return <picture>
		{attrs.source.map(a => <source key={a.type}{...a} />)}
		<img alt={props.alt} {...attrs.img} className={props.className} style={props.style} />
	</picture>
}