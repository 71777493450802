import { Link } from "gatsby"
import * as React from "react"
import * as styles from './header2.module.scss'
import { ImagoPicture } from "./ImagoPic"
import Nav from "./nav"
import { Lang } from "./pagine"

export default function Header2(props: { lang: Lang }) {

	return <header className={styles.header}>
		<Link className={styles.logo} to={props.lang == 'en' ? '/en' : '/'}>
			<ImagoPicture name="sito/logo.png" alt="logo" />
			{/* <div style={{ display: "inline-flex", alignItems: "center", width: "3em", height: "3em", border: "1px solid black" }}>LOGO</div> */}
		</Link>
		<h1 className={styles.titolo}>Antonio Gallenga</h1>
		<div className={styles.menu}><Nav lang={props.lang} /></div>
		{/* da nascondere per rendere centrato il titolo */}
	</header>
}