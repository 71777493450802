import { images } from "../images"


function src(nome: string, i: number, ext: string) {
	return `/dataImg/${nome}/${i}.${ext}`
}

function srcset(nome: string, ww: number[], ext: string) {
	return ww.map((w, i) => `${src(nome, i, ext)} ${w}w`).join(', ')
}

export function imagoSrc(nome: string): {
	source: { srcSet: string, type: string }[]
	img: { src: string }
} {
	nome = nome.toLowerCase()
	const ww = images[nome]

	if (!ww)
		throw new Error("Missing image " + nome)

	return {
		img: { src: src(nome, 0, 'jpg') },
		source: [
			{ type: 'image/webp', srcSet: srcset(nome, ww, 'webp') },
			{ type: 'image/jpeg', srcSet: srcset(nome, ww.slice(0, 1), 'jpg') },
		]
	}
}
