import * as React from "react"
import { Tfooter } from "../testi/footer"
import * as styles from './footer.module.scss'
import { Lang } from "./pagine"



export function Footer(props: { lang: Lang, floating?: boolean }) {
	return <footer className={styles.footer + " " + (props.floating ? styles.floating : styles.nofloating)}>
		<a href="/it/p/chi_siamo" style={{ color: 'inherit' }}>
			<Tfooter lang={props.lang} />
		</a>
	</footer>
}
