import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, Lang } from "../components/componentiPagine"

export function Tfooter(props: {lang: Lang}) {

if (props.lang == "en")
	return <><p>Pariatur sint esse duis sunt ex occaecat do ullamco magna. (en)</p>
</>


if (props.lang == "it")
	return <><p>Un progetto del Comune di Bedonia con il contributo della Consulta degli emiliano romagnoli nel mondo realizzato dall'Associazione Appennino Ritrovato</p>
</>

	return null
}